import React from 'react'
import Layout from '../components/layout'
import DataScientist from '../components/categories/data-scientist'
import Seo from '../components/seo'

const DataScientistPage = ({ location }) => {
  return (
    <Layout location={location}>
      <DataScientist />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Data Scientist Freelancers | Codestaff'
  const description =
    'Hire the best Data Scientist freelancers at Codestaff. Get the top 1% of Data Scientist professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default DataScientistPage
