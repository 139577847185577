import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`
const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Do Data Scientists Do?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Codestaff data scientists analyze data for actionable insights. They devise and apply models and algorithms to mine the stores of big data to identify patterns and trends. They also identify data analytic problems, determine the correct data set and variables and create the greatest opportunities for your organization."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Are The Benefits Of Data Science For Business?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A data scientist adds value to any business. From statistics to insights across workflows and employing new candidates, data science helps senior staff make a better-informed decision and it is valuable to any company or business that is ready to grow. Codestaff can help you."
        }
    }
}

const DataScientist = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Data Scientists.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Data Scientists. Top
                                    companies and start-ups choose Codestaff Data Science experts
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Data Scientist now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE DATA SCIENTISTS' banner='/data-scientist.png' bannerAlt='data scientist banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default DataScientist